import React, { useState, useEffect, useRef } from "react";
import '../../css/EditEmpleado.css';
import Button from '../../components/Button';
import { putMiPerfilEmpleado, putUsuario } from '../../utils/peticionesApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';

const EditEmpleado = ({ setEditMode, persona, id, usuario, setPersona, setUsuario, password, listarPersona }) => {

  const isMounted = useRef(true);

  const availableRoles = ["ROLE_ADMIN", "ROLE_USER", "ROLE_RRHH", "ROLE_SUPERVISOR"];
  const roleLabels = {
    "ROLE_ADMIN": "Administrador",
    "ROLE_USER": "Empleado",
    "ROLE_RRHH": "RRHH",
    "ROLE_SUPERVISOR": "Supervisor"
  };

  const fieldLabels = {
    street: "Domicilio",
    number: "Número",
    floor: "Piso",
    apartament: "Departamento",
    cell_phone: "Teléfono celular",
    email: "Correo electrónico",
    name_emergency_contact: "Contacto de emergencia",
    emergency_contact: "Teléfono de emergencia",
    username: "Usuario",
    appUserRoles: "Rol",
  };

  const getLabel = (field) => fieldLabels[field] || field;

  const initialRole = Array.isArray(usuario?.appUserRoles) && usuario.appUserRoles.length > 0
    ? usuario.appUserRoles[0]
    : usuario?.appUserRoles || "";

  const [editedInfo, setEditedInfo] = useState({
    address_dto: {
      apartament: persona?.address?.apartament || "",
      floor: persona?.address?.floor || "",
      number: persona?.address?.number || "",
      street: persona?.address?.street || "",
    },
    status: {
      active: !!persona?.status?.active,
      comments: persona?.status?.comments || "",
      entry_date: persona?.status?.entry_date || "",
      leaving_date: persona?.status?.leaving_date || "",
    },
    cell_phone: persona?.cell_phone || "",
    email: persona?.email || "",
    emergency_contact: persona?.emergency_contact || "",
    name_emergency_contact: persona?.name_emergency_contact || "",
    username: usuario?.username || "",
    appUserRoles: initialRole,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateForm = () => {
    if (editedInfo.email && !/\S+@\S+\.\S+/.test(editedInfo.email)) {
      setError("El email no es válido");
      return false;
    }
    if (editedInfo.username.trim() === "") {
      setError("El nombre de usuario no puede estar vacío");
      return false;
    }
    setError(null);
    return true;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) return;

    setLoading(true);
    setError(null);

    try {
      const perfilData = {
        address_dto: editedInfo.address_dto,
        status: {
          ...editedInfo.status,
          entry_date: editedInfo.status.entry_date || "", // Preserva fechas vacías como ""
          leaving_date: editedInfo.status.leaving_date || "",
        },
        cell_phone: editedInfo.cell_phone,
        email: editedInfo.email,
        emergency_contact: editedInfo.emergency_contact,
        name_emergency_contact: editedInfo.name_emergency_contact,
      };

      const completeUsuarioData = {
        username: editedInfo.username,
        email: usuario.email,
        password: password,
        active: usuario.active,
        company: usuario.company,
        appUserRoles: [editedInfo.appUserRoles],
      };

      const shouldUpdatePerfil = Object.values(perfilData).some(value =>
        typeof value === "object"
          ? Object.values(value).some(v => v !== "")
          : value !== ""
      );

      if (shouldUpdatePerfil) {
        const perfilResponse = await putMiPerfilEmpleado(id, perfilData);
        if (isMounted.current) setPersona(perfilResponse.data);
      }

      const userChanged =
        completeUsuarioData.username !== usuario.username ||
        JSON.stringify(completeUsuarioData.appUserRoles) !== JSON.stringify(usuario.appUserRoles);

      if (userChanged) {
        const usuarioResponse = await putUsuario(usuario.id, completeUsuarioData);
        if (isMounted.current) setUsuario(usuarioResponse.data);
      }
      if (listarPersona) await listarPersona();

      if (isMounted.current) setEditMode(false);
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      if (isMounted.current) setError("Hubo un error al guardar los cambios. Por favor, inténtalo de nuevo más tarde.");
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  const handleInputChange = (e, field, nestedField) => {
    setEditedInfo((prevInfo) => ({
      ...prevInfo,
      [field]: nestedField
        ? { ...prevInfo[field], [nestedField]: e.target.value }
        : e.target.value,
    }));
  };

  const isValidDate = (date) => {
    const regex = /^\d{2}-\d{2}-\d{4}$/; // Verifica formato DD-MM-YYYY
    return regex.test(date);
  };

  return (
    <div className="contenedor-editEmpleado">
      <button
        onClick={() => {
          setEditMode(false);
        }}
        className="buttonVolverObj"
      >
        <FontAwesomeIcon icon={faCircleLeft} />
        Volver
      </button>

      <div className="contenedor-button-edit-empleado">
        <p className="titulo-edit-empleado">Editar empleado</p>
        <Button msj="Guardar" onClick={handleSaveClick} disabled={loading} />
      </div>

      {loading && <p>Cargando...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Datos empleado */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Empleador</p>
        <div className="contenedorDireccion">
          <div>
            <p>{getLabel("active")}:</p>
            <select
              className="input-edit-empleado"
              value={editedInfo.status.active ? "true" : "false"}
              onChange={(e) => {
                const newValue = e.target.value === "true";
                handleInputChange({ target: { value: newValue } }, "status", "active");
              }}
            >
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>

          </div>
          {["entry_date", "leaving_date", "comments"].map((field) => (
            <div key={field}>
              <p>{getLabel(field)}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo.status[field] || ""}
                onChange={(e) => handleInputChange(e, "status", field)}
              />
            </div>
          ))}

        </div>
      </div>

      {/* Dirección */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Dirección</p>
        <div className="contenedorDireccion">
          {["street", "number", "floor", "apartament"].map((field) => (
            <div key={field}>
              <p>{getLabel(field)}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo.address_dto[field]}
                onChange={(e) => handleInputChange(e, "address_dto", field)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Contacto */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Contacto</p>
        <div className="contenedorDireccion">
          {["cell_phone", "email", "name_emergency_contact", "emergency_contact"].map((field) => (
            <div key={field}>
              <p>{getLabel(field)}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo[field]}
                onChange={(e) => handleInputChange(e, field)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Usuario */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Usuario</p>
        <div className="contenedorDireccion">
          <div>
            <p>{getLabel("username")}:</p>
            <input
              className="input-edit-empleado"
              value={editedInfo.username}
              onChange={(e) => handleInputChange(e, "username")}
            />
          </div>
          <div>
            <p>{getLabel("appUserRoles")}:</p>
            <select
              className="input-edit-empleado"
              value={editedInfo.appUserRoles}
              onChange={(e) => handleInputChange(e, "appUserRoles")}
            >
              {availableRoles.map((role) => (
                <option key={role} value={role}>
                  {roleLabels[role] || role}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmpleado;