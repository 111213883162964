// Empleado.js

import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import "../css/Empleados.css";
import "../css/Home.css";
import "../css/Stepper.css";
import CustomPagination from "../components/CustomPagination";
import AgregarEmpleadoModal from "../forms/Empleado/AgregarEmpleadoModal";
import { NewContext } from "../utils/ContextProvider";
import {
  getPersona,
  postEmpleado,
  postPicture,
  getExportEmployee,
  deleteEmployee,
} from "../utils/peticionesApi";
import OptionComponenteEmpleado from "../components/OptionComponenteEmpleado";
import ImportarEmpleadoModal from '../forms/Empleado/ImportarEmpleadoModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare, faFileArrowDown, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Button from "../components/Button";

const Empleado = () => {
  const { getToken, getCompany, getAlert } = useContext(NewContext);
  const [personas, setPersonas] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const employeesPerPage = 20;
  const history = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [modalViewExport, setModalViewExport] = useState(false);
  const [modalDeleteEmpleado, setModalDeleteEmpleado] = useState(false);
  const navigate = useNavigate();

  const listarPersonas = async (currentPage, employeesPerPage) => {
    try {
      const response = await getPersona(currentPage, employeesPerPage);
      setPersonas(response.data.content);
      setCurrentPage(currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  const guardarFotoPerfil = async (id, file) => {
    if (!file) {
      console.error("Selecciona un archivo antes de importar.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await postPicture(formData, getCompany, id);
      if (response.status === 200) {
        console.log("Imagen importada con éxito.");
      } else {
        console.error("Hubo un problema al importar la imagen.");
      }
    } catch (error) {
      console.error(
        "Error al importar la imagen:",
        error.response.data.message
      );
    }
  };

  useEffect(() => {
    if (!getToken) return;

    if (searchTerm !== "") {
      listarPersonas(0, 300);
    } else {
      listarPersonas(currentPage, employeesPerPage);
    }
  }, [searchTerm, selectedEmployee, currentPage, getToken]);

  const filteredEmpleados =
    personas?.filter((empleado) => {
      const fullName = empleado.person.name + " " + empleado.person.lastname;
      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        empleado.person.national_id.includes(searchTerm)
      );
    }) || [];

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const verEmpleado = (id) => {
    history(`/empleado/${id}`);
  };

  const [formData, setFormData] = useState({
    blood_group: "A*",
    cell_phone: "2223233232",
    address: {
      apartament: "",
      floor: 0,
      number: 0,
      street: "",
    },
    city: {
      id: 0,
      id_district: 0,
      name: "",
      zip_code: 0,
    },
    history: {
      comments: "",
      company: "",
      from_date: "",
      id: 0,
      reference: "",
      to_date: "",
    },
    person: {
      date_of_birth: "",
      lastname: "",
      name: "",
      national_id: "",
    },
    status: {
      active: true,
      comments: "",
      entry_date: "",
      leaving_date: "",
    },
    user: {
      company: getCompany,
      username: "",
      roles: "",
    },
    email: "",
    app_role: null,
    app_status: null,
    area: [1],
    card_no: "",
    company: 1,
    department: 1,
    dev_privilege: 2,
    device_password: "",
    emp_code: 0,
    emp_type: null,
    enable_att: true,
    enable_holiday: true,
    enable_overtime: true,
    enroll_sn: "",
    flow_role: [0],
    gender: "M",
    national: "",
    nickname: "",
    position: 0,
    postcode: "",
    religion: "",
    save_in_zkteco: false,
    self_password: "",
    ssn: 0,
    verify_mode: 0,
  });

  const [formDataUser, setFormDataUser] = useState({
    username: "",
    email: "",
    password: "",
    active: true,
    company: getCompany,
    appUserRoles: ["ROLE_USER"],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const dateRegex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    const isDateValid =
      name === "person.date_of_birth" ? dateRegex.test(value) : true;

    setFormData((prevFormData) => {
      const updatedFormData = JSON.parse(JSON.stringify(prevFormData));

      const nameParts = name.split(".");

      let currentLevel = updatedFormData;
      for (let i = 0; i < nameParts.length; i++) {
        const part = nameParts[i];

        if (i === nameParts.length - 1) {
          if (!currentLevel[part]) {
            currentLevel[part] = {};
          }

          currentLevel[part] = value;
        } else {
          if (!currentLevel[part]) {
            currentLevel[part] = {};
          }
          currentLevel = currentLevel[part];
        }
      }

      return updatedFormData;
    });
  };

  const handleSubmit = async (selectedImage) => {
    try {
      const formDataToSend = {
        address: {
          street: formData.address.street,
          number: formData.address.number,
          apartament: formData.address.apartament,
          floor: formData.address.floor,
        },
        blood_group: formData.blood_group,
        cell_phone: formData.cell_phone,
        city: {
          name: formData.city.name,
        },
        history: {
          comments: formData.history.comments,
          company: formData.history.company,
          from_date: formData.history.from_date,
          reference: formData.history.reference,
          to_date: formData.history.to_date,
        },
        person: {
          date_of_birth: formData.person.date_of_birth,
          lastname: formData.person.lastname,
          name: formData.person.name,
          national_id: formData.person.national_id,
        },
        status: {
          active: formData.status.active,
          comments: formData.status.comments,
          entry_date: formData.status.entry_date,
          leaving_date: formData.status.leaving_date,
        },
        user: {
          company: getCompany,
          username: formData.user.username,
          roles: Array.isArray(formData.user.roles)
            ? formData.user.roles
            : [formData.user.roles],
        },
        email: formData.email,
        app_role: 1,
        app_status: 0,
        area: [1],
        card_no: "",
        company: 1,
        department: 1,
        dev_privilege: 0,
        device_password: "",
        emp_code: formData.person.national_id,
        emp_type: null,
        enable_att: false,
        enable_holiday: true,
        enable_overtime: true,
        enroll_sn: "",
        flow_role: null,
        gender: "M",
        national: "Argentina",
        nickname: formData.person.name,
        position: null,
        postcode: formData.city.zip_code,
        religion: null,
        save_in_zkteco: formData.save_in_zkteco || false,
        self_password: formData.person.national_id,
        ssn: 0,
        verify_mode: 0,
      };

      const response = await agregarEmpleado(formDataToSend);
      if (response.data.success) {
        const partes = response.data.details[0].split(":");
        const id = partes[1].trim();
        const responseFoto = await guardarFotoPerfil(id, selectedImage);
        if (
          response &&
          response.data &&
          response.data.details &&
          responseFoto &&
          responseFoto.data &&
          responseFoto.data.details
        ) {
        } else {
          console.log("Hubo un problema al agregar el empleado.");
        }
      } else {
        const errors = response.data.details;
        const errorMessage = errors.join("\n");
      }
    } catch (error) {
      console.error("Error al agregar el empleado:", error);
    }
  };

  const agregarEmpleado = async (dto) => {
    try {
      const response = await postEmpleado(dto);
      if (response.status === 200) {
        if (response.data.success) {
          closeModal();
          getAlert({ msj: "Empleado agregado con éxito.", tipe: "succes" });
          listarPersonas(0, 300);
        }
        return response;
      } else {
        console.error("Hubo un problema al agregar el empleado.");
      }
    } catch (error) {
      console.error(
        "Error al agregar el empleado:",
        error.response.data.details[0]
      );
      console.log("Respuesta con error:", error.response);
      setError(error.response.data.message);
    }
  };

  const resetForm = () => {
    setFormData({
      address: {
        apartament: "",
        floor: 0,
        number: 0,
        street: "",
      },
      blood_group: "",
      cell_phone: "",
      city: {
        id: 0,
        id_district: 0,
        name: "",
        zip_code: 0,
      },
      // emergency_contact: '',
      // name_emergency_contact: '',
      email: "",
      history: {
        comments: "",
        company: "",
        from_date: "",
        id: 0,
        reference: "",
        to_date: "",
      },
      person: {
        date_of_birth: "",
        lastname: "",
        name: "",
        national_id: "",
      },
      // picture: {
      //   url: 'https://www.google.com.ar/?hl=es"'
      // },
      status: {
        active: true,
        comments: "",
        entry_date: "",
        leaving_date: "",
      },
      user: {
        company: "",
        roles: "",
        username: "",
      },
      app_role: null,
      app_status: null,
      area: [1],
      card_no: "",
      company: 1,
      department: 1,
      dev_privilege: 2,
      device_password: "",
      emp_code: 0,
      emp_type: null,
      enable_att: true,
      enable_holiday: true,
      enable_overtime: true,
      enroll_sn: null,
      flow_role: null,
      gender: null,
      national: "",
      nickname: "",
      position: 0,
      postcode: "",
      religion: "",
      save_in_zkteco: false,
      self_password: null,
      ssn: null,
      verify_mode: null,
    });
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuVisible && !event.target.closest(".menu-container")) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  const agregarFunction = () => {
    resetForm();
    setActiveStep(0);
    openModal();
  };

  const openImportModal = () => {
    setShowImportModal(true);
  };

  const closeImportModal = () => {
    setShowImportModal(false);
  };

  const handleEditClick = (id) => {
    navigate(`/empleado/${id}`);
  };

  const handleDownloadClick = (id, name, lastname) => {
    getExportEmployee(id, name, lastname)
      .then(() => {
        console.log('Export successful');
      })
      .catch((error) => {
        console.error('Export failed:', error);
      });
  };

  const eliminarEmpleado = async () => {
    try {
      const response = await deleteEmployee(selectedEmployeeId);
      if (response.status === 200) {
        getAlert({ msj: "Empleado eliminado con éxito.", tipe: 'succes' });
        setModalDeleteEmpleado(false); // Cierra el modal solo en caso de éxito
        listarPersonas(currentPage, employeesPerPage); // Actualiza la lista
      } else {
        getAlert({ msj: response.data.message });
      }
    } catch (error) {
      console.log("Error al eliminar el empleado:", error);
      getAlert({ msj: "Error al eliminar el empleado.", tipe: 'error' });
    }
  };

  return (
    <>
      <div className="employeecontainer">
        <p className="titulo">
          {showModal ? "Agregar empleado" : "Empleados"}
        </p>

        {showModal ? (
          <AgregarEmpleadoModal
            showModal={showModal}
            closeModal={closeModal}
            formData={formData}
            formDataUser={formDataUser}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            activeStep={activeStep}
            currentPage={currentPage}
            error={error}
            setFormData={setFormData}
          />
        ) : (
          <div className="data-table">
            <div style={{ display: "flex", alignItems: "center" }}>
              <OptionComponenteEmpleado
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                agregarFunction={agregarFunction}
                upload={openImportModal}
                setModalViewExport={setModalViewExport}
                modalViewExport={modalViewExport}
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Apellido</th>
                  <th scope="col">DNI</th>
                  <th scope="col">Fecha de nac.</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody title="ver perfil">
                {(searchTerm !== "" ? filteredEmpleados : personas)?.map(
                  (empleado, index) => (
                    <tr
                      className="pointer"
                      key={index}
                      onClick={() => {
                        verEmpleado(empleado.id);
                      }}
                    >
                      <td>{empleado.person.name}</td>
                      <td>{empleado.person.lastname}</td>
                      <td>{empleado.person.national_id}</td>
                      <td>{empleado.person.date_of_birth}</td>
                      <td>{empleado.city.name}</td>
                      <td>
                        <span
                          className={`${empleado.status.active ? "itemActive" : "itemInactive"
                            }`}
                        >
                          {empleado.status.active ? "Activo" : "Inactivo"}
                        </span>
                      </td>
                      <td
                        className="contenedor-item"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="icon-edit"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEditClick(empleado.id);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="icon-salida"
                          onClick={(event) => {
                            event.stopPropagation();
                            setSelectedEmployeeId(empleado.id);
                            setModalDeleteEmpleado(true);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          className="icon-File"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDownloadClick(
                              empleado.id,
                              empleado.person.name,
                              empleado.person.lastname
                            );
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
                {searchTerm !== "" && filteredEmpleados.length === 0 && (
                  <tr>
                    <td colSpan="7">No se encontraron resultados.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center mt-4">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        )}
        <ImportarEmpleadoModal
          showModal={showImportModal}
          closeModal={closeImportModal}
        />

        {modalDeleteEmpleado && (
          <div className="modalDelete">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="iconoDelete"
            />
            <p>¿Estás seguro de eliminar este empleado?</p>
            <div className="contenedorButonsDelete">
              <Button
                tipe="sinBg"
                msj="Cancelar"
                onClick={() => setModalDeleteEmpleado(false)}
              />
              <Button msj="Confirmar" onClick={eliminarEmpleado} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Empleado;
