import React, { useState, useContext } from "react";
import "../../css/AgregarVisitante.css";
import SelectEmpleados from "../../components/SelectEmpleados";
import BarcodeScanner from "../../components/BarcodeScanner";
import Button from '../../components/Button'
import { postVisit, putVisit } from "../../utils/peticionesApi";
import { NewContext } from "../../utils/ContextProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdBadge, faLocationDot, faUserInjured } from '@fortawesome/free-solid-svg-icons';

const AgregarVisitante = ({ setModalView, visitaUpdate, listarVisitas }) => {
  
  const [scannerActive, setScannerActive] = useState(true);
  const { getAlert } = useContext(NewContext);
  const [dataVisit, setDataVisit] = useState({
    address: visitaUpdate ? visitaUpdate.address : "",
    entry_date_time: visitaUpdate ? `${visitaUpdate.entry_date} ${visitaUpdate.entry_time}` : "",
    lastname: visitaUpdate ? visitaUpdate.lastname : "",
    name: visitaUpdate ? visitaUpdate.name : "",
    national_id: visitaUpdate ? visitaUpdate.national_id : "",
    observations: visitaUpdate ? visitaUpdate.observations : "",
    uuid_employee: visitaUpdate ? visitaUpdate.uuid_employee : "",
    exit_date_time: visitaUpdate ? `${visitaUpdate.entry_date} ${visitaUpdate.exit_time}` : ''
  });


  const handleChange = (e) => {
    setDataVisit({ ...dataVisit, [e.target.name]: e.target.value });
  };

  const handleChangeUuid = (empleadoUuid) => {
    setDataVisit({ ...dataVisit, uuid_employee: empleadoUuid });
  };

  const formatTwoDigits = (num) => (num < 10 ? '0' + num : num);

  const handleSave = async () => {
    const dto = dataVisit;
    const now = new Date();
    const day = formatTwoDigits(now.getDate());
    const month = formatTwoDigits(now.getMonth() + 1);
    const year = now.getFullYear();
    const hours = formatTwoDigits(now.getHours());
    const minutes = formatTwoDigits(now.getMinutes());
    const seconds = formatTwoDigits(now.getSeconds());

    dto.entry_date_time = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    if (!dataVisit.name || !dataVisit.lastname || !dataVisit.national_id || !dataVisit.uuid_employee) {
      getAlert({ msj: "Por favor, complete los campos obligatorios: Nombre, Apellido , DNI y A quien visita? .", tipe: 'error' });
      return;
    }

    try {
      const response = await postVisit(dataVisit);
      if (response.status === 200) {
        getAlert({ msj: "Visita agregada con éxito.", tipe: 'succes' });
      } else {
        getAlert({ msj: response.data.message })
      }
      listarVisitas();
      setModalView(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSave = async () => {
    const dto = dataVisit;

    if (!dto.exit_date_time || dto.exit_date_time.includes("undefined")) {
      delete dto.exit_date_time;
    }

    dto.id = visitaUpdate.id;
    dto.present_on_site = visitaUpdate.present_on_site;

    try {
      const response = await putVisit(dto);
      if (response.status === 200) {
        getAlert({ msj: "Visitante modificado con éxito.", tipe: 'succes' });
      } else {
        getAlert({ msj: response.data.message })
      }
      listarVisitas();
      setModalView(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleScan = (scannedData) => {
    console.log("Datos escaneados:", scannedData);

    // Separar los datos por comillas dobles (") para extraer información relevante
    const parts = scannedData.split('"');

    if (parts.length >= 6) {
      const apellido = parts[1]; // Apellido
      const nombres = parts[2]; // Nombre completo
      const dni = parts[4]; // DNI

      // Actualizar el estado con los valores extraídos para que los inputs se llenen automáticamente
      setDataVisit((prevData) => ({
        ...prevData,
        lastname: apellido,
        name: nombres,
        national_id: dni
      }));

      console.log("Procesado -> Apellido:", apellido, "Nombre:", nombres, "DNI:", dni);
    } else {
      console.error("Formato de escaneo inesperado:", scannedData);
    }
  };

  return (
    <div className="contenedorAgregarVisitante">
      <BarcodeScanner onScan={handleScan} /> {/* Captura el escaneo y actualiza los inputs */}
      <p className="tituloAgregarVisitante">Registro de visita</p>
      <div className="contenedorDatosVisitante">
        <div className="datosVisitante">
          <p>Nombre</p>
          <div className="input-container">
            <FontAwesomeIcon icon={faUser} className="icon-input" />
            <input
              name="name"
              type="text"
              placeholder="Nombre del visitante"
              value={dataVisit.name} // Se llena automáticamente al escanear
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="datosVisitante">
          <p>Apellido</p>
          <div className="input-container">
            <FontAwesomeIcon icon={faUserInjured} className="icon-input" />
            <input
              name="lastname"
              type="text"
              placeholder="Apellido del visitante"
              value={dataVisit.lastname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="datosVisitante">
          <p>DNI</p>
          <div className="input-container">
            <FontAwesomeIcon icon={faIdBadge} className="icon-input" />
            <input
              name="national_id"
              type="text"
              placeholder="DNI del visitante"
              value={dataVisit.national_id}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="datosVisitante">
          <p>Domicilio</p>
          <div className="input-container">
            <FontAwesomeIcon icon={faLocationDot} className="icon-input" />
            <input
              name="address"
              type="text"
              placeholder="Dirección del visitante"
              value={dataVisit.address}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="contenedorDatosVisitante">
        <div className="datosVisitanteEmpleado">
          <p>Visita a</p>
          <SelectEmpleados setUuid={handleChangeUuid} defaultValue={visitaUpdate?.uuid_employee} setScannerActive={setScannerActive} />
          </div>
        <div className="datosVisitanteEmpleado">
          <p>Comentario</p>
          <textarea name="observations" value={dataVisit.observations} onChange={handleChange}></textarea>
        </div>
      </div>
      <div className="contenedorButtonsAgrVisita">
        <Button tipe="sinBg" onClick={() => setModalView(false)}>Cancelar</Button>
        <Button onClick={visitaUpdate ? updateSave : handleSave}>Guardar</Button>
      </div>
    </div>
  );
}

export default AgregarVisitante;