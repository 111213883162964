import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../../css/Solicitudes.css";
import CustomPagination from "../../components/CustomPagination";
import { NewContext } from "../../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRequestHistory } from "../../utils/peticionesApi";
import { useParams } from "react-router-dom";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';

const SolicitudHistorial = () => {
    const { getToken, getRol, getId, getAlert } = useContext(NewContext);
    const [request, setRequest] = useState([]);
    const [reasons, setReason] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);

    const { id, uuid } = useParams();

    useEffect(() => {
        if (getToken && getId) {
            listarRequestHistory();
        }
    }, [getToken, getId]);

    const listarRequestHistory = async () => {
        let response;
        try {
            if (id) {
                response = await getRequestHistory(uuid, id);
            }

            if (response && response.data) {
                setRequest(response.data);
            } else {
                getAlert({ msj: "Error: La respuesta no contiene datos.", tipe: "error" });
            }
        } catch (error) {
            if (error.response) {
                getAlert({ msj: `Error en la solicitud: ${error.response.status} - ${error.response.statusText}`, tipe: "error" });
            } else {
                getAlert({ msj: "Error al hacer la solicitud: " + error.message, tipe: "error" });
            }
        }
    };

    const formatDateLocalTime = (dateArray) => {
        if (!Array.isArray(dateArray) || dateArray.length < 6) {
            return "Fecha inválida";
        }

        const [year, month, day, hours, minutes, seconds] = dateArray;

        const formattedDate = `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

        return `${formattedDate} ${formattedTime}`;
    };

    const formatDate = (dateArray) => {
        if (!Array.isArray(dateArray) || dateArray.length !== 3) {
            return "Fecha inválida";
        }

        const [year, month, day] = dateArray;
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    };

    const filtradoSolicitudes = useMemo(() => {
        return request?.filter((r) => {
            const searchLowerCase = searchTerm.toLowerCase();
            return (
                r.employee_first_name?.toLowerCase().includes(searchLowerCase) ||
                r.employee_last_name?.toLowerCase().includes(searchLowerCase) ||
                reasons.find(reason => reason.id === r.id_reason)?.reason?.toLowerCase().includes(searchLowerCase) ||
                formatDate(r.request_since).includes(searchLowerCase) ||
                r.observations_request?.toLowerCase().includes(searchLowerCase) ||
                (r.active ? "aprobado" : "pendiente").toLowerCase().includes(searchLowerCase)
            );
        });
    }, [searchTerm, request, reasons]);

    const solicitudesPorPagina = 15;
    const paginatedSolicitudes = useMemo(() => {
        const start = currentPage * solicitudesPorPagina;
        const end = start + solicitudesPorPagina;
        return filtradoSolicitudes.slice(start, end);
    }, [currentPage, filtradoSolicitudes]);


    const TableRow = ({ request }) => {
        const motivo = reasons.find((reason) => reason.id === request.id_reason);
        const motivoName = motivo ? motivo.reason : "Motivo Desconocido";

        return (
            <tr>
                <td>{request.employeeFirstName + " " + request.changedByLastName}</td>
                <td>{request.reason}</td>
                <td>{formatDate(request.requestSince)}</td>
                <td>{request.observations}</td>
                <td>{request.changedByFirstName + " " + request.changedByLastName}</td>
                <td>{formatDateLocalTime(request.changeDate)}</td>
                <td>
                    <span
                        className={`itemActive ${request.status === "APROBADA"
                            ? ""
                            : request.status === "RECHAZADA"
                                ? "itemInactive"
                                : request.status === "OBSERVADA"
                                    ? "itemObserved"
                                    : "itemPending"
                            }`}
                    >
                        {request.status === "APROBADA"
                            ? "Aprobado"
                            : request.status === "RECHAZADA"
                                ? "Rechazado"
                                : request.status === "OBSERVADA"
                                    ? "Observado"
                                    : "Pendiente"}
                    </span>
                </td>
            </tr>
        );
    };

    return (
        <div className="contenedor-solicitudes">
            {id && (
                <button
                    onClick={() => window.history.back()}
                    className="buttonVolverObj"
                >
                    <FontAwesomeIcon icon={faCircleLeft} />
                    Volver
                </button>
            )}
            <p className="titulo">
                Historial de Solicitud
            </p>
            <div className="data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Solicitante</th>
                            <th>Motivo</th>
                            <th>Fecha</th>
                            <th style={{ width: "300px" }}>Observaciones</th>
                            <th>Modificado Por</th>
                            <th>Fecha Modificacíon</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedSolicitudes.length === 0 ? (
                            <tr>
                                <td colSpan="6">No se encontraron resultados.</td>
                            </tr>
                        ) : (
                            paginatedSolicitudes.map((request, index) => (
                                <TableRow key={index} request={request} />
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-center mt-4">
                <div className="d-flex justify-content-center mt-4">
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(filtradoSolicitudes.length / solicitudesPorPagina)}
                        setCurrentPage={setCurrentPage}
                        hasNextPage={currentPage < Math.ceil(filtradoSolicitudes.length / solicitudesPorPagina) - 1}
                    />
                </div>
            </div>
        </div>
    );
};

export default SolicitudHistorial;