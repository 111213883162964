import axios from "axios"

export const axiosInterceptor = () => {
    const updateHeaders = (request) => {
        const token = sessionStorage.getItem("access_token");

        // Siempre asignar el token
        request.headers['Authorization'] = `Bearer ${token}`;

        // Si la petición NO es FormData, forzamos 'Content-Type' a 'application/json'
        // Caso contrario, dejamos el Content-Type intacto (para multipart/form-data).
        const isFormData = request.data instanceof FormData;
        if (!isFormData) {
            request.headers['Content-Type'] = 'application/json';
        }

        return request;
    };

    axios.interceptors.request.use((req) => {
        // No modificamos el header en la ruta de login
        if (req.url?.includes('login')) return req;

        return updateHeaders(req);
    });

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 403) {
                sessionStorage.removeItem("access_token");
                window.location.href = "/";
            }
            return Promise.reject(error);
        }
    );
};
