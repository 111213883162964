import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getPersona } from '../utils/peticionesApi';

const SelectEmpleados = ({ setUuid, defaultValue, setScannerActive }) => {
    const [empleados, setEmpleados] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const findEmpleados = async () => {
            try {
                const res = await getPersona();
                if (res?.data?.content) {
                    const newEmpleados = res.data.content.map((empleado) => ({
                        value: `${empleado.id}`,
                        label: `${empleado.person.name} ${empleado.person.lastname} DNI: ${empleado.person.national_id}`
                    }));
                    setEmpleados(newEmpleados);

                    if (defaultValue) {
                        const defaultOption = newEmpleados.find(option => option.value === defaultValue);
                        setSelectedOption(defaultOption);
                    }
                }
            } catch (error) {
                console.error("Error cargando empleados:", error);
            }
        };
        findEmpleados();
    }, [defaultValue]);

    const handleChange = (select) => {
        setUuid(select.value);
        setSelectedOption(select);
    };

    return (
        <Select 
            options={empleados || []} 
            onChange={handleChange} 
            placeholder='Buscar empleado' 
            value={selectedOption} 
            classNamePrefix="react-select"
            onMenuOpen={() => setScannerActive?.(false)}  // ✅ Solo se ejecuta si setScannerActive está definido
            onMenuClose={() => setScannerActive?.(true)}  // ✅ Evita errores si no se pasa la prop
        />
    );
};

export default SelectEmpleados;
