import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { getVacacionesUuid, putVacacionesUuid } from "../../utils/peticionesApi";

import "react-calendar/dist/Calendar.css";
import "../../css/Vacaciones.css";

import { NewContext } from "../../utils/ContextProvider";

const Vacaciones = () => {
    const { id } = useParams();
    const [vacaciones, setVacaciones] = useState(null);
    const [nuevasVacaciones, setNuevasVacaciones] = useState("");
    const { getAlert } = useContext(NewContext);

    useEffect(() => {
        const obtenerVacaciones = async () => {
            try {
                const response = await getVacacionesUuid(id);
                if (response.status === 200 && response.data.details) {
                    const vacacionesText = response.data.details[0];
                    const vacacionesNum = parseInt(vacacionesText.match(/\d+/)?.[0] || "0", 10);
                    setVacaciones(vacacionesNum);
                }
            } catch (error) {
                console.log("Error al obtener vacaciones:", error);
                setVacaciones(0);
            }
        };

        if (id) obtenerVacaciones();
    }, [id]);

    const handleUpdateVacaciones = async () => {
        if (!nuevasVacaciones || isNaN(nuevasVacaciones) || nuevasVacaciones < 0) {
            getAlert({ msj: "Ingrese una cantidad válida de días de vacaciones", tipe: "warning" });

            return;
        }

        try {
            const response = await putVacacionesUuid(id, parseInt(nuevasVacaciones, 10));
            if (response.status === 200) {
                getAlert({ msj: "Días de vacaciones actualizados correctamente", tipe: "succes" });
                setVacaciones(parseInt(nuevasVacaciones, 10));
                setNuevasVacaciones("");
            }
        } catch (error) {
            console.log("Error al actualizar vacaciones:", error);
            getAlert({ msj: "Error al actualizar días de vacaciones", tipe: "error" });
        }
    };

    return (
        <div className="contenedor-solicitudes">
            {id && (
                <button
                    onClick={() => window.history.back()}
                    className="buttonVolverObj"
                >
                    <FontAwesomeIcon icon={faCircleLeft} />
                    Volver
                </button>
            )}
            <p className="titulo">Vacaciones</p>
            <h4>Cantidad de Vacaciones Pendientes: {vacaciones !== null ? vacaciones : "Cargando..."}</h4>

            <div className="update-vacaciones">
                <input
                    type="number"
                    placeholder="Ingrese nuevos días"
                    value={nuevasVacaciones}
                    onChange={(e) => setNuevasVacaciones(e.target.value)}
                />
                <button onClick={handleUpdateVacaciones}>Actualizar Vacaciones</button>
            </div>
        </div>
    );
};

export default Vacaciones;
