import { React, useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NewContext } from "../utils/ContextProvider";
import { getMiPerfil, putMiPerfil, putImagenPerfil } from "../utils/peticionesApi";
import { URL_PICTURE } from "../utils/urls";
import '../css/MiPerfil.css';
import Button from '../components/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faAsterisk, faEnvelope, faFolder, faLocationDot, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "../components/LoadingOverlay"; // Importar el componente LoadingOverlay
import defaultProfilePicture from '../image/Logo-jornada.svg';

function MiPerfil() {
  const { getId, getCompany } = useContext(NewContext);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    address_dto: {
      apartament: "",
      floor: 0,
      id: 0,
      number: 0,
      street: ""
    },
    cell_phone: "",
    email: "",
    emergency_contact: "",
    name_emergency_contact: ""
  });
  const [isLoading, setIsLoading] = useState(false); // Estado para gestionar la carga
  const history = useNavigate();
  const fileInputRef = useRef(null);

  const listarMiPerfil = async () => {
    try {
      setIsLoading(true); // Activar el estado de carga
      const response = await getMiPerfil(getId);
      setSelectedEmployee(response.data);
      setFormData({
        address_dto: {
          apartament: response.data.address?.apartament || "",
          floor: response.data.address?.floor || 0,
          id: response.data.address?.id || 0,
          number: response.data.address?.number || 0,
          street: response.data.address?.street || ""
        },
        cell_phone: response.data.cell_phone || "",
        email: response.data.email || "",
        emergency_contact: response.data.emergency_contact || "",
        name_emergency_contact: response.data.name_emergency_contact || ""
      });
    } catch (error) {
      console.error("Error al listar el perfil", error);
    } finally {
      setIsLoading(false); // Desactivar el estado de carga
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("address_dto.")) {
      const addressField = name.split(".")[1];
      setFormData({
        ...formData,
        address_dto: {
          ...formData.address_dto,
          [addressField]: value
        }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await putMiPerfil(getId, formData);
      setIsEditing(false);
      listarMiPerfil();
    } catch (error) {
      console.error("Error al guardar los datos", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No se seleccionó ningún archivo.");
      return;
    }

    // Validaciones de archivo
    if (file.size > 500000) {
      console.error("El archivo es demasiado grande.");
      return;
    }

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      console.error("Tipo de archivo no permitido.");
      return;
    }

    console.log("Archivo seleccionado:", file);

    const formDataImage = new FormData();
    formDataImage.append("file", file);

    try {
      setIsLoading(true); // Activar el estado de carga
      const company = typeof getCompany === "function" ? getCompany() : getCompany;

      // Subir imagen al servidor
      await putImagenPerfil(getId, formDataImage, company);

      // Actualizar datos después de cargar la imagen
      listarMiPerfil();
    } catch (error) {
      console.error("Error al cargar la imagen:", error);
    } finally {
      setIsLoading(false); // Desactivar el estado de carga
    }
  };

  useEffect(() => {
    if (getId) {
      listarMiPerfil();
    }
  }, [getId]);

  return (
    <div className="miperfil-container">
      <LoadingOverlay isLoading={isLoading} /> {/* Overlay de carga */}
      <p className="titulo">Mi perfil</p>

      <div className="subcontenedor-miperfil">
        {getId && (
          <div className="contenedor-imgPerfil">
            <img
              src={`${URL_PICTURE}getProfile?uuid=${getId}&company=${getCompany}&${Date.now()}`}
              className="img-miperfil"
              alt="fotoPerfil"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProfilePicture;
              }}
            />
            <div className="contenedor-button-img-perfil">
              <Button msj='Cambiar imagen' onClick={handleChangeImageClick} />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        )}
        <div className="contenedor-datos-miperfil">
          {selectedEmployee && (
            <div className="contenedor-datos-perfil">
              <div className="contenedor-dato-perfil" title='Nombre'>
                <FontAwesomeIcon icon={faUser} className="icon-miprofile" />
                <p>{`${selectedEmployee.person.name} ${selectedEmployee.person.lastname}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="DNI">
                <FontAwesomeIcon icon={faAddressCard} className="icon-miprofile" />
                <p>{`DNI: ${selectedEmployee.person.national_id}`}</p>
              </div>
              <div className="contenedor-dato-perfil" title="Dirección">
                <FontAwesomeIcon icon={faLocationDot} className="icon-miprofile" />
                {isEditing ? (
                  <div className="direccion-inputs">
                    <input
                      type="text"
                      name="address_dto.street"
                      value={formData.address_dto.street}
                      onChange={handleInputChange}
                      placeholder="Calle"
                      className="input-miperfil"
                    />
                    <input
                      type="number"
                      name="address_dto.number"
                      value={formData.address_dto.number}
                      onChange={handleInputChange}
                      placeholder="Número"
                      className="input-miperfil"
                    />
                  </div>
                ) : (
                  <p>{`${selectedEmployee.address.street} ${selectedEmployee.address.number}`}</p>
                )}
              </div>
              <div className="contenedor-dato-perfil" title="Teléfono">
                <FontAwesomeIcon icon={faPhone} className="icon-miprofile" />
                {isEditing ? (
                  <input
                    type="text"
                    name="cell_phone"
                    value={formData.cell_phone}
                    onChange={handleInputChange}
                    className="input-miperfil"
                  />
                ) : (
                  <p>{selectedEmployee.cell_phone || 'Sin contacto'}</p>
                )}
              </div>
              <div className="contenedor-dato-perfil" title="Contacto de emergencia">
                <FontAwesomeIcon icon={faAsterisk} className="icon-miprofile" />
                {isEditing ? (
                  <input
                    type="text"
                    name="name_emergency_contact"
                    value={formData.name_emergency_contact}
                    onChange={handleInputChange}
                    className="input-miperfil"
                  />
                ) : (
                  <p>{selectedEmployee.name_emergency_contact}</p>
                )}
              </div>
              <div className="contenedor-dato-perfil" title="Email">
                <FontAwesomeIcon icon={faEnvelope} className="icon-miprofile" />
                {isEditing ? (
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="input-miperfil"
                  />
                ) : (
                  <p>{selectedEmployee.email}</p>
                )}
              </div>
              <div className="contenedor-btnEdit">
                {isEditing ? (
                  <>
                    <Button msj='Guardar' tipe="link" onClick={handleSave} />
                    <Button msj='Cancelar' tipe="sinBg" onClick={() => setIsEditing(false)} />
                  </>
                ) : (
                  <Button msj='Editar datos de perfil' tipe="link" onClick={() => setIsEditing(true)} />
                )}
                <Button tipe="sinBg" msj='Documentación'>
                  <FontAwesomeIcon icon={faFolder} className="icon-miprofile" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MiPerfil;