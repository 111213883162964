const URL_EMPLOYEE = "https://www.softnik.live/employee";
const URL_USER = "https://www.softnik.fun/user/";
const URL_DISTRICT = "https://www.softnik.live/district";
const URL_CITY = "https://www.softnik.live/city";
const URL_PROVINCE = "https://www.softnik.live/province";
const URL_PICTURE = "https://www.softnik.cloud/awsProfile/";
const URL_REGISTER = "https://www.softnik.live/registers";
const URL_REGISTERS = "https://www.softnik.live/registers/dayPresent";
const URL_ASISTENCIA = "https://www.softnik.live/registry/signon";
const URL_REGSITRY = "https://www.softnik.live/registry/assistance";
const URL_EMPLOYEE_IMPORT = "https://www.softnik.live/employee/upload";
const URL_REGISTRY_IMPORT = "https://www.softnik.live/upload/assistance";
const URL_REGISTRYARRAY = "https://www.softnik.live/registry/array";
const URL_REGISTRYDELETE = "https://www.softnik.live/registry/deleteByIds";
const URL_REGISTRYUPDATE = "https://www.softnik.live/registry/update";
const URL_OBJETIVOS = "https://www.softnik.live/objective";
const URL_REQUEST = "https://www.softnik.live/request";
const URL_REQUESTHISTORY = "https://www.softnik.live/request-history";
const URL_REASON = "https://www.softnik.live/reason";
const URL_DOCUMENTACION = 'https://www.softnik.live/documentation';
const URL_REPORTES = 'https://www.softnik.live/reports/statistics';
const URL_EXPORT_WITH_DETAIL = 'https://www.softnik.live/api/attendance/export/excel/with-detail';
const URL_EXPORT_WITHOUT_DETAIL = 'https://www.softnik.live/api/attendance/export/excel/without-detail';
const URL_PLACE = 'https://www.softnik.live/place';
const URL_PERIOD = 'https://www.softnik.live/period';
const URL_VISIT = 'https://www.softnik.live/visit';
const URL_TRANSACTION = 'https://www.softnik.live/api/zkteco/transactions';
const URL_TRANSACTION_REPORT = 'https://www.softnik.live/api/zkteco/report/';
const URL_DEPARTAMENT = 'https://www.softnik.live/api/zkteco/';
const URL_FOLDER = 'https://www.softnik.live/api/v1/filesystem';
const URL_STADISTICS = 'https://www.softnik.live/stadistics';
const URL_STADISTICSARRIVAL = 'https://www.softnik.live/stadistics/lateArrivals';
const URL_STATUS = 'https://www.softnik.live/status/General';
const URL_BIRDAYS = 'https://www.softnik.live/stadistics/birthdaysMonth';
const URL_STADISTICS_MONTHLY = 'https://www.softnik.live/stadistics/statistics/monthly';
const URL_STADISTICS_ANNUAL = 'https://www.softnik.live/stadistics/statistics/annual';
const URL_SUSPENSION = 'https://www.softnik.live/suspension';
const URL_ABSENCE = 'https://www.softnik.live/absence';
const URL_LATE = 'https://www.softnik.live/late';
const URL_HOLIDAYS = 'https://www.softnik.live/holidays'; ///test
export {
  URL_HOLIDAYS,
  URL_LATE,
  URL_ABSENCE,
  URL_CITY,
  URL_PROVINCE,
  URL_PICTURE,
  URL_USER,
  URL_EMPLOYEE,
  URL_DISTRICT,
  URL_REGISTERS,
  URL_REGISTER,
  URL_ASISTENCIA,
  URL_REGSITRY,
  URL_REGISTRY_IMPORT,
  URL_EMPLOYEE_IMPORT,
  URL_REGISTRYDELETE,
  URL_OBJETIVOS,
  URL_REQUEST,
  URL_REQUESTHISTORY,
  URL_REASON,
  URL_DOCUMENTACION,
  URL_REPORTES,
  URL_EXPORT_WITH_DETAIL,
  URL_EXPORT_WITHOUT_DETAIL,
  URL_PLACE,
  URL_PERIOD,
  URL_REGISTRYARRAY,
  URL_REGISTRYUPDATE,
  URL_VISIT,
  URL_TRANSACTION,
  URL_TRANSACTION_REPORT,
  URL_FOLDER,
  URL_STADISTICS,
  URL_STADISTICSARRIVAL,
  URL_STATUS,
  URL_BIRDAYS,
  URL_SUSPENSION,
  URL_DEPARTAMENT
};
