import { React, useContext, useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import '../css/Empleado.css';
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faAsterisk, faCheck, faTimes, faLocationDot, faPen, faPhone, faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getDocumentacion, getPersonaId, getReportes, getExportEmployee, getDateUser, putPerfil } from "../utils/peticionesApi";
import EditEmpleado from "../forms/Empleado/EditEmpleado";
import { Link } from 'react-router-dom';
import defaultProfilePicture from '../image/Logo-jornada.svg';
import ButtonBack from "../components/ButtonBack";

const Empleado = () => {
  const { getCompany, getId, getRol } = useContext(NewContext)
  const [persona, setPersona] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [documentacion, setDocumentacion] = useState(null);
  const [reporte, setReporte] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [detalleAsistencia, setDetalleAsistencia] = useState(false)
  const username = sessionStorage.getItem("username");

  const { id } = useParams()
  const ulrPicturePersonal = 'https://www.softnik.cloud/awsProfile/getProfile';
  const monthNames = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  const month = new Date().getMonth() + 1;
  const monthName = monthNames[month - 1];
  const annual = new Date().getFullYear();

  const roleLabels = {
    "ROLE_ADMIN": "Administrador",
    "ROLE_USER": "Empleado",
    "ROLE_RRHH": "RRHH",
    "ROLE_SUPERVISOR": "Supervisor"
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleExportClick = () => {
    getExportEmployee(id, persona.person.name, persona.person.lastname)
      .then(() => {
        console.log('Export successful');
      })
      .catch((error) => {
        console.error('Export failed:', error);
      });
  };

  const listarPersona = async () => {
    const response = await getPersonaId(id);
    setPersona(response.data);
  };

  const listarUsuario = async () => {
    if (!persona?.email) {
      console.log("No hay email disponible para listar el usuario");
      return;
    }
    const response = await getDateUser(persona.email);
    setUsuario(response.data);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const obtenerReportes = async () => {
    if (getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR') {
      try {
        let fromDate, toDate;

        if (!detalleAsistencia) {
          fromDate = new Date(annual, month - 1, 1);
          toDate = new Date(annual, month, 0);
        } else {
          fromDate = new Date(annual, 0, 1);
          toDate = new Date(annual, 11, 31);
        }

        const fromDateString = formatDate(fromDate);
        const toDateString = formatDate(toDate);

        const response = await getReportes(id, fromDateString, toDateString);
        setReporte(response.data);

      } catch (error) {
        console.log(error);
      }
    }
  };

  const obtenerDocumentacion = async () => {
    try {
      const response = await getDocumentacion(id)
      if (response.status === 200) setDocumentacion(response.data);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await listarPersona();
        obtenerReportes();
        obtenerDocumentacion();
      }
    };
    fetchData();
  }, [id, getRol, detalleAsistencia]);

  useEffect(() => {
    const fetchUsuario = async () => {
      if (persona?.email) {
        await listarUsuario();
      }
    };

    fetchUsuario();
  }, [persona]);

  const getReadableRoles = () => {
    const roleMapping = {
      ROLE_ADMIN: "ADMINISTRADOR",
      ROLE_USER: "EMPLEADO",
      ROLE_RRHH: "RRHH",
      ROLE_SUPERVISOR: "SUPERVISOR",
    };

    if (usuario?.appUserRoles) {
      if (typeof usuario.appUserRoles === "string") {
        return usuario.appUserRoles
          .split(", ")
          .map((role) => roleMapping[role] || role)
          .join(", ");
      }

      if (Array.isArray(usuario.appUserRoles)) {
        return usuario.appUserRoles
          .map((role) => roleMapping[role] || role)
          .join(", ");
      }
      return "Formato de roles no soportado";
    }
    return "Sin rol asignado";
  };

  const [editandoCampo, setEditandoCampo] = useState(null);
  const [personaEditada, setPersonaEditada] = useState(persona);

  // Función para activar el modo edición en un campo específico
  const handleEdit = (campo) => {
    setEditandoCampo(campo);
    setPersonaEditada(persona); // Copia los datos actuales antes de editar
  };

  // Función para cancelar la edición
  const handleCancel = () => {
    setEditandoCampo(null);
    setPersonaEditada(persona); // Restaura los valores originales
  };

  const handleSave = async (campo) => {
    if (!personaEditada || !personaEditada.person) {
      console.error("❌ Error: personaEditada o personaEditada.person es null");
      return;
    }

    try {
      const perfilData = {
        ...persona,
        email: personaEditada.email ?? persona.email ?? "sin_email@empresa.com", // ✅ Asegurar email
        cell_phone: personaEditada.cell_phone ?? persona.cell_phone,
        blood_group: personaEditada.blood_group ?? persona.blood_group,
        emp_code: persona.emp_code ?? 0,
        nickname: persona.nickname ?? "",
        gender: persona.gender ?? "",
        religion: persona.religion ?? "",
        postcode: persona.postcode ?? "",
        national: persona.national ?? "",
        enable_att: persona.enable_att ?? true,
        enable_holiday: persona.enable_holiday ?? true,
        enable_overtime: persona.enable_overtime ?? true,
        verify_mode: persona.verify_mode ?? 0,
        zkteco_employee_id: persona.zkteco_employee_id ?? 0,

        person: {
          id: persona.person.id ?? 0,
          name: persona.person.name ?? "",
          lastname: persona.person.lastname ?? "",
          national_id: personaEditada.person.national_id ?? persona.person.national_id,
          date_of_birth: personaEditada.person.date_of_birth ?? persona.person.date_of_birth,
        },

        address: {
          id: persona.address?.id ?? 0,
          street: personaEditada.address?.street ?? persona.address?.street,
          number: personaEditada.address?.number ?? persona.address?.number,
          floor: personaEditada.address?.floor ?? persona.address?.floor,
          apartament: personaEditada.address?.apartament ?? persona.address?.apartament,
        },

        city: {
          id: persona.city?.id ?? 0,
          name: persona.city?.name ?? "",
          zip_code: persona.city?.zip_code ?? 0,
          id_district: persona.city?.id_district ?? 0,
        },

        status: {
          id: persona.status?.id ?? 0,
          active: Boolean(personaEditada.status.active),
          entry_date: persona.status?.entry_date ?? "",
          leaving_date: persona.status?.leaving_date ?? "",
          comments: persona.status?.comments ?? "",
        },

        user: {
          id: personaEditada?.user?.id ?? persona?.user?.id ?? 0,
          company: personaEditada?.user?.company ?? persona?.user?.company ?? "",
          username: personaEditada?.user?.username ?? persona?.user?.username ?? "",
          roles: Array.isArray(personaEditada?.user?.appUserRoles)
            ? personaEditada?.user?.appUserRoles
            : [personaEditada?.user?.appUserRoles || "ROLE_USER"], // Asegurar que es un array
        },

        history: {
          id: persona.history?.id ?? 0,
          comments: persona.history?.comments ?? "",
          company: persona.history?.company ?? "",
          from_date: persona.history?.from_date ?? "",
          to_date: persona.history?.to_date ?? "",
          reference: persona.history?.reference ?? "",
        },

        emergency_contact: personaEditada.emergency_contact ?? persona.emergency_contact,
        name_emergency_contact: personaEditada.name_emergency_contact ?? persona.name_emergency_contact,

        flow_role: persona.flow_role ?? [],
        area: persona.area ?? [],
        ///department: persona.department ?? 0,
        position: persona.position ?? 0,
        ///company: persona.company ?? 0,
        self_password: persona.self_password ?? "",
        device_password: persona.device_password ?? "",
        ssn: persona.ssn ?? 0,
        enroll_sn: persona.enroll_sn ?? "",
        save_in_zkteco: Boolean(personaEditada.save_in_zkteco),
        vacation_days_available: persona.vacation_days_available ?? 0,
      };

      console.log("✅ Enviando datos corregidos al backend:", perfilData);

      const perfilResponse = await putPerfil(id, perfilData);

      if (perfilResponse && perfilResponse.success !== false) {
        setPersona(perfilResponse.data);
        setEditandoCampo(null);
        window.location.reload();
      } else {
        console.error("❌ Error en la respuesta del servidor:", perfilResponse);
      }
    } catch (error) {
      console.error("❌ Error al actualizar:", error);
    }
  };


  return (
    <div className="empleadoContainer">
      <ButtonBack />
      {editMode && (
        <EditEmpleado
          setEditMode={setEditMode}
          persona={persona}
          id={id}
          usuario={usuario}
          setPersona={setPersona}
          setUsuario={setUsuario}
          password={persona?.person?.national_id}
          listarPersona={listarPersona}
        />
      )}
      <div className="rectangulo">
        {getId && (
          <img
            alt="foto perfil"
            src={`${ulrPicturePersonal}?uuid=${id}&company=${getCompany}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultProfilePicture;
            }}
          />
        )}
        {persona && (
          <div className="contenedor-datos-empleado">
            <div className="contenedor-nombre-empleado">
              <h2>{persona.person && `${persona.person.name} ${persona.person.lastname}`}</h2>
            </div>

            <div className="datos-empleado">
              {/* Estado */}
              <div>
                <div className="titulo-item-datos">Estado</div>
                {editandoCampo === "status" ? (
                  <div className="editando">
                    <select
                      value={personaEditada?.status?.active ? "activo" : "inactivo"}
                      onChange={(e) =>
                        setPersonaEditada({
                          ...personaEditada,
                          status: { ...personaEditada?.status, active: e.target.value === "activo" } // 🔥 Asegurar booleano
                        })
                      }
                    >
                      <option value="activo">Activo</option>
                      <option value="inactivo">Baja</option>
                    </select>
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("status")} />
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("status")} className={persona?.status?.active ? "status-activo" : "status-inactivo"}>
                    {persona?.status?.active ? "Activo" : "Baja"}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>

              {/* Alta Laboral */}
              <div>
                <div className="titulo-item-datos">Alta Laboral</div>
                {editandoCampo === "entry_date" ? (
                  <div className="editando">
                    <input
                      type="text"
                      style={{ width: "75px" }}
                      value={personaEditada?.status?.entry_date || ""}
                      onChange={(e) => {
                        setPersonaEditada({
                          ...personaEditada,
                          status: { ...personaEditada.status, entry_date: e.target.value }, // ✅ Ahora sí actualiza bien
                        });
                      }}
                    />
                    {/* Guardar cambios */}
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("entry_date")} />
                    {/* Cancelar edición */}
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("entry_date")}>
                    {persona?.status?.entry_date || "Sin fecha"}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>

              {/* DNI */}
              <div>
                <div className="titulo-item-datos">DNI</div>
                {editandoCampo === "national_id" ? (
                  <div className="editando">
                    <input
                      type="text"
                      style={{ width: "75px" }}
                      value={personaEditada?.person?.national_id || ""}
                      onChange={(e) =>
                        setPersonaEditada({
                          ...personaEditada,
                          person: { ...personaEditada.person, national_id: e.target.value },
                        })
                      }
                    />
                    {/* Guardar cambios en backend */}
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("national_id")} />
                    {/* Cancelar edición */}
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("national_id")}>
                    {persona.person && persona.person.national_id}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>


              {/* FECHA DE NACIMIENTO */}
              <div>
                <div className="titulo-item-datos">F. NAC</div>
                {editandoCampo === "date_of_birth" ? (
                  <div className="editando">
                    <input
                      type="text"
                      style={{ width: "75px" }}
                      value={personaEditada?.person?.date_of_birth || ""}
                      onChange={(e) =>
                        setPersonaEditada({
                          ...personaEditada,
                          person: { ...personaEditada.person, date_of_birth: e.target.value },
                        })
                      }
                    />
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("date_of_birth")} />
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("date_of_birth")}>
                    {persona.person && persona.person.date_of_birth}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>

              {/* Ciudad */}
              <div>
                <div className="titulo-item-datos">Ciudad</div>
                {editandoCampo === "city" ? ( // 🔥 Debes comparar con "city", no "city.name"
                  <div className="editando">
                    <input
                      type="text"
                      value={personaEditada?.city?.name || ""} // ✅ Usa city?.name para evitar errores si city es undefined
                      onChange={(e) =>
                        setPersonaEditada({
                          ...personaEditada,
                          city: { ...personaEditada.city, name: e.target.value }, // ✅ Modifica city correctamente
                        })
                      }
                      style={{ width: "100px" }} // Ajusta el tamaño
                    />
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("city")} />
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("city")}> {/* 🔥 Corregí el handleEdit */}
                    {persona?.city?.name || "Sin ciudad"}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>

              <div>
                <p className="titulo-item-datos">Reloj</p>
                {editandoCampo === "save_in_zkteco" ? (
                  <div className="editando">
                    <input
                      type="checkbox"
                      checked={personaEditada?.save_in_zkteco || false}
                      onChange={(e) =>
                        setPersonaEditada({
                          ...personaEditada,
                          save_in_zkteco: e.target.checked,
                        })
                      }
                    />
                    {/* Guardar cambios */}
                    <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("save_in_zkteco")} />
                    {/* Cancelar edición */}
                    <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
                  </div>
                ) : (
                  <p onClick={() => handleEdit("save_in_zkteco")}>
                    <input type="checkbox" checked={persona?.save_in_zkteco || false} disabled /> {/* Solo visualmente */}
                    <FontAwesomeIcon icon={faPen} className="icono-editar" />
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="subdatos-empleado">
        <div>
          <div className="contenedor-item-datos">
            <FontAwesomeIcon icon={faLocationDot} className="icon-subdatos" />
            <p className="titulo-item-datos">Dirección</p>
          </div>
          <p className="contenido-item-datos">{persona?.address && `${persona.address.street} ${persona.address.number} Piso ${persona.address.floor} Departamento ${persona.address.apartament}`}</p>
        </div>

        <div>
          <div className="titulo-item-datos" > <FontAwesomeIcon icon={faPhone} className="icon-subdatos" />Teléfono</div>
          {editandoCampo === "cell_phone" ? (
            <div className="editando">
              <input
                type="number"
                value={personaEditada?.cell_phone || ""}
                onChange={(e) =>
                  setPersonaEditada({ ...personaEditada, cell_phone: e.target.value })
                }
                style={{ width: "100px", fontSize: "12px" }} // Ajusta el tamaño
              />
              <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("cell_phone")} />
              <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
            </div>
          ) : (
            <p style={{ fontSize: "12px" }} onClick={() => handleEdit("cell_phone")}>
              {persona?.cell_phone || "Sin teléfono registrado"}
              <FontAwesomeIcon icon={faPen} className="icono-editar" />
            </p>
          )}
        </div>

        <div>
          <div className="contenedor-item-datos">
            <FontAwesomeIcon icon={faEnvelope} className="icon-subdatos" />
            <p className="titulo-item-datos">Mail</p>
          </div>
          <p className="contenido-item-datos">{persona?.email} </p>
        </div>
        <div>
          <div className="titulo-item-datos">Contacto de emergencia</div>
          {editandoCampo === "emergency_contact" ? (
            <div className="editando">
              <input
                type="text"
                placeholder="Nombre"
                value={personaEditada?.name_emergency_contact || ""}
                onChange={(e) =>
                  setPersonaEditada({ ...personaEditada, name_emergency_contact: e.target.value })
                }
                style={{ width: "120px", marginRight: "5px", fontSize: "12px" }}
              />
              <input
                type="text"
                placeholder="Teléfono"
                value={personaEditada?.emergency_contact || ""}
                onChange={(e) =>
                  setPersonaEditada({ ...personaEditada, emergency_contact: e.target.value })
                }
                style={{ width: "120px", fontSize: "12px" }}
              />
              {/* Guardar cambios */}
              <FontAwesomeIcon icon={faCheck} className="icono-check" onClick={() => handleSave("emergency_contact")} />
              {/* Cancelar edición */}
              <FontAwesomeIcon icon={faTimes} className="icono-cancel" onClick={handleCancel} />
            </div>
          ) : (
            <p style={{ fontSize: "12px" }} onClick={() => handleEdit("emergency_contact")}>
              {persona?.name_emergency_contact || "Sin contacto"}: {persona?.emergency_contact || "Sin teléfono"}
              <FontAwesomeIcon icon={faPen} className="icono-editar" />
            </p>
          )}
        </div>
        <div>
          <div className="contenedor-item-datos">
            <FontAwesomeIcon icon={faAsterisk} className="icon-subdatos" />
            <p className="titulo-item-datos">Usuario</p>
          </div>
          <p className="contenido-item-datos">{usuario?.username}</p>
        </div>
        <div>
          <div className="contenedor-item-datos">
            <FontAwesomeIcon icon={faAsterisk} className="icon-subdatos" />
            <p className="titulo-item-datos">Rol</p>
          </div>
          <p className="contenido-item-datos">{getReadableRoles()}</p>
        </div>
      </div>
      <div className="contenedorTituloAsistenciaHorario">
        <h2 className="titulo-asistencia-empleado">Asistencias y horarios</h2>
        <div className="contenedorBotonesFiltroAsistencia">
          <button onClick={() => setDetalleAsistencia(!detalleAsistencia)} className={`${!detalleAsistencia ? "buttonAsistenciaSelected" : "buttonAsistenciaNoSelected"}`}>Mes</button>
          <button onClick={() => setDetalleAsistencia(!detalleAsistencia)} className={`${detalleAsistencia ? "buttonAsistenciaSelected" : "buttonAsistenciaNoSelected"}`}>Año</button>
        </div>
      </div>
      <div className="rectangulo3">
        {reporte && (
          <div className="cuadrado">
            <div className="contenedor-porcential-titulo">
              <Link to={`/ausencias/${id}`} style={{ textDecoration: 'none', color: '#3F63AC' }}>
                Ausencias
              </Link>
              <p>Total de Ausencias: {reporte?.absences}</p>
            </div>
            <div className="contenedor-progress" style={{ "--porcentual": reporte?.absences }}>
              <svg width='50' height='50'>
                <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
              </svg>
              <span className="number-progress">{reporte?.absences?.toFixed(1)}%</span>
            </div>
          </div>
        )}
        {reporte && (
          <div className="cuadrado">
            <div className="contenedor-porcential-titulo">
              <Link to={`/tardanzas/${id}`} style={{ textDecoration: 'none', color: '#3F63AC' }}>
                Llegadas tardes
              </Link>
              <p>Total de llegadas tardes: {reporte?.lateArrivals}</p>
            </div>
            <div>
              <div className="contenedor-progress" style={{ "--porcentual": reporte?.lateArrivals }}>
                <svg width='50' height='50'>
                  <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                  <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                </svg>
                <span className="number-progress">{reporte?.lateArrivals?.toFixed(1)}%</span>
              </div>
            </div>
          </div>
        )}
        {reporte && (
          <div className="cuadrado">
            <div className="contenedor-porcential-titulo">
              <Link to={`/suspensiones/${id}`} style={{ textDecoration: 'none', color: '#3F63AC' }}>
                Suspensiones
              </Link>
              <p>Total de Suspensiones: {reporte?.suspension}</p>
            </div>
            <div>
              <div className="contenedor-progress" style={{ "--porcentual": reporte?.suspension }}>
                <svg width='50' height='50'>
                  <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                  <circle className="progress" r='20' cx='50%' cy='50%' pathLength='100'></circle>
                </svg>
                <span className="number-progress">{reporte?.suspension?.toFixed(1)}%</span>
              </div>
            </div>
          </div>
        )}
        {reporte && (
          <div className="cuadrado">
            <div className="contenedor-porcential-titulo">
              <Link to={`/solicitudes/${id}`} style={{ textDecoration: 'none', color: '#3F63AC' }}>
                Solicitudes
              </Link>
              <p>Cantidad solicitudes: {reporte?.request}</p>
            </div>
            <div>
              <div className="contenedor-progress" style={{ "--porcentual": reporte?.requestPercentage }}>
                <svg width="50" height="50">
                  <circle className="progress" r="20" cx="50%" cy="50%" pathLength="100"></circle>
                  <circle className="progress" r="20" cx="50%" cy="50%" pathLength="100"></circle>
                </svg>
                <span className="number-progress">
                  {reporte?.requestPercentage?.toFixed(1)}%
                </span>
              </div>
            </div>
          </div>
        )}
        {reporte && (
          <div className="cuadrado">
            <div className="contenedor-porcential-titulo">
              <Link to={`/vacaciones/${id}`} style={{ textDecoration: 'none', color: '#3F63AC' }}>
                Vacaciones
              </Link>
              <p>Cantidad Pendientes: {persona?.vacation_days_available}</p>
            </div>
            <div>
              <div className="contenedor-progress" style={{ "--porcentual": reporte?.requestPercentage }}>
                <svg width="50" height="50">
                  <circle className="progress" r="20" cx="50%" cy="50%" pathLength="100"></circle>
                  <circle className="progress" r="20" cx="50%" cy="50%" pathLength="100"></circle>
                </svg>
                <span className="number-progress">
                  {reporte?.requestPercentage?.toFixed(1)}%
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <h2 className="titulo-asistencia-empleado">Documentación</h2>
      {persona && documentacion ? (
        <div className="rectangulo4">
          <div className="cuadrados">
            <h5>Cursos</h5>
            <div>
              <p>{documentacion.observations_course}</p>
            </div>
          </div>
          <div className="barra-lateral"></div>
          <div className="cuadrados">
            <h5>Psicofísico</h5>
            <div>
              <p>{documentacion.observations_psychophysical}</p>
            </div>
          </div>
          <div className="barra-lateral"></div>
          <div className="cuadrados">
            <h5>Documentación</h5>
            <div>
              <p>{documentacion.observations_rnr}</p>
            </div>
          </div>
        </div>
      ) :
        <div className="contenedor-sin-documentacion">
          <FontAwesomeIcon icon={faCheck} />
          <p>No hay documentación pendiente</p>
        </div>
      }

    </div>

  );
};

export default Empleado;