import { useEffect, useState, useContext } from 'react';
import { putFolder, getFolderContents, downloadFile, importarFile, postFolders, deleteFolder, moveFolder, uploadMultipleFiles } from '../utils/peticionesApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faCircleLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import OptionComponentDocuments from "../forms/Documentos/OptionComponentDocuments";
import '../css/Documentos.css';
import Button from "../components/Button";
import { NewContext } from "../utils/ContextProvider";

const Documentos = () => {
    const [contenidos, setContenidos] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [rutaActual, setRutaActual] = useState([]);
    const [modalViewExport, setModalViewExport] = useState(false);
    const [modalView, setModalView] = useState(false);
    const { getAlert, getUUID, getId } = useContext(NewContext);
    const [modalCreateFolder, setModalCreateFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [showOptions, setShowOptions] = useState(null);
    const [showOptions2, setShowOptions2] = useState(null);
    const [file, setFile] = useState(null);
    const [draggingFile, setDraggingFile] = useState(null); // Archivo en movimiento
    const [draggingOverFolder, setDraggingOverFolder] = useState(null); // Carpeta sobre la que se arrastra

    const handleDragStart = (fileName) => {
        console.log(`🚀 Arrastrando archivo: ${fileName}`);
        setDraggingFile(fileName);
    };

    const handleDragOver = (event, folderName) => {
        event.preventDefault(); // Necesario para permitir soltar el archivo
        setDraggingOverFolder(folderName);
    };


    // ✅ Nueva variable para resaltar el área cuando un archivo se arrastra
    const [dragging, setDragging] = useState(false);

    // ✅ Manejador para soltar archivos (Drop)
    const handleDrop = async (event) => {
        event.preventDefault();
        setDragging(false); // Quitar el resaltado

        const files = event.dataTransfer.files; // 📂 Capturar archivos

        if (files.length === 0) return;

        // ✅ Extraer solo la última carpeta si estamos dentro de una
        let filePath = currentFolder;
        if (!filePath || filePath === getId) {
            filePath = ''; // Si estamos en la raíz, no enviar path
        } else {
            const pathSegments = filePath.split('/');
            filePath = pathSegments[pathSegments.length - 1]; // Tomar solo la última carpeta
        }

        console.log(`📂 Subiendo ${files.length} archivo(s) a:`, filePath);

        for (let file of files) {
            await importarFile(getId, file, filePath); // 📌 Subir archivo
        }

        listarContenidos(currentFolder); // 🔄 Refrescar lista
    };


    // ✅ Manejador para cuando el usuario deja de arrastrar
    const handleDragLeave = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (!modalViewExport) {
            setSelectedItems([]);
        }
    }, [modalViewExport]);

    const listarContenidos = async (folder) => {
        try {
            const folderPath = folder || '';
            const resp = await getFolderContents(folderPath, '');

            // Verificamos si la respuesta tiene la estructura esperada
            if (resp && resp.success && resp.folders && resp.files) {
                setContenidos({
                    carpetas: resp.folders,  // Almacenar carpetas
                    archivos: resp.files     // Almacenar archivos
                });
            } else {
                console.error('Respuesta inesperada:', resp);
                setContenidos({ carpetas: [], archivos: [] });
            }
        } catch (error) {
            console.error('Error al listar carpetas:', error);
            setContenidos({ carpetas: [], archivos: [] });
        }
    };

    useEffect(() => {
        listarContenidos('');
    }, []);

    const abrirCarpeta = (folderName) => {
        // Construir la nueva ruta
        const nuevaCarpeta = currentFolder ? `${currentFolder}/${folderName}` : folderName;

        // Actualizar la ruta actual
        setRutaActual([...rutaActual, folderName]);
        setCurrentFolder(nuevaCarpeta);

        // Listar los contenidos de la nueva carpeta
        listarContenidos(nuevaCarpeta);
    };

    const descargarArchivo = (file) => {
        const fileName = file.name; // Asegurar que obtenemos el nombre correcto
        const filePath = currentFolder ? `${currentFolder}/${fileName}` : fileName;

        console.log("🛠 Descargando:", { fileName, filePath });

        downloadFile(getId, fileName, filePath);
    };


    const handleSelectItem = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(i => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const contenidosFiltrados = Array.isArray(contenidos)
        ? contenidos.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const carpetas = contenidos.carpetas || [];
    const archivos = contenidos.archivos || [];

    const volverAtras = () => {
        if (rutaActual.length > 0) {
            const nuevaRuta = [...rutaActual];
            nuevaRuta.pop();
            const nuevaCarpeta = nuevaRuta.join('/') || '';
            setRutaActual(nuevaRuta);
            setCurrentFolder(nuevaCarpeta);
            listarContenidos(nuevaCarpeta);
        }
    };

    const entrarEnCarpeta = (folderName) => {
        setCurrentFolder(prevPath => {
            return prevPath ? `${prevPath}/${folderName}` : folderName;
        });
        listarContenidos(currentFolder); // 🔄 Refrescar la vista con los nuevos archivos
    };


    const handleCreateFolder = async () => {
        try {
            if (newFolderName) {
                let folderPath = currentFolder;

                // ✅ Si estamos en la raíz, no enviamos `path`
                if (!folderPath || folderPath === getId) {
                    folderPath = '';
                } else {
                    // ✅ Extraer solo la última parte del `path` para que coincida con lo que espera la API
                    const pathSegments = folderPath.split('/');
                    folderPath = pathSegments[pathSegments.length - 1]; // Tomar solo la última carpeta
                }

                console.log("📂 Creando carpeta en:", folderPath); // 📌 Debugging

                const response = await postFolders(getId, true, newFolderName, folderPath);

                if (response.status === 200) {
                    getAlert({ msj: "Carpeta creada con éxito.", tipe: 'success' });
                    setNewFolderName('');
                    setModalCreateFolder(false);
                    listarContenidos(currentFolder); // 🔄 Refrescar el listado
                } else {
                    getAlert({ msj: response.data.message, tipe: 'error' });
                }
            } else {
                getAlert({ msj: "El nombre de la carpeta no puede estar vacío.", tipe: 'warning' });
            }
        } catch (error) {
            console.error('Error al crear la carpeta:', error);
            getAlert({ msj: "Error al crear la carpeta.", tipe: 'error' });
        }
    };


    const eliminarCarpeta = async (folderName) => {
        try {
            console.log("Eliminar carpeta:", folderName, "path:", currentFolder);

            // ✅ Enviar path solo si la carpeta no está en la raíz
            const response = await deleteFolder(folderName, currentFolder, getId);

            if (response.status === 200) {
                getAlert({ msj: 'Carpeta eliminada con éxito.', tipe: 'success' });
                listarContenidos(currentFolder); // 🔄 Refrescar contenido
            } else {
                getAlert({ msj: response.data.message, tipe: 'error' });
            }
        } catch (error) {
            console.error('Error al eliminar la carpeta:', error);
            getAlert({ msj: 'Error al eliminar la carpeta.', tipe: 'error' });
        }
    };

    const toggleOptions = (index) => {
        setShowOptions(showOptions === index ? null : index);
    };


    const toggleOptions2 = (index2) => {
        setShowOptions(showOptions === index2 ? null : index2);
    };

    const descargarSeleccionados = () => {
        if (selectedItems.length === 0) {
            getAlert({ msj: 'No has seleccionado archivos para exportar.', tipe: 'warning' });
            return;
        }

        selectedItems.forEach(file => {
            downloadFile(getId, file.name, currentFolder); // 📌 Pasar los parámetros correctamente
        });

        setSelectedItems([]); // Limpiar selección después de exportar
    };

    const handleFileUpload = async (event) => {
        const selectedFile = event.target.files[0]; // 📂 Archivo seleccionado

        if (!selectedFile) {
            console.error("⚠️ No has seleccionado ningún archivo.");
            return;
        }

        // ✅ Extraer solo el nombre de la carpeta actual
        let filePath = currentFolder;

        if (!filePath || filePath === getId) {
            filePath = ''; // Si estamos en la raíz, no enviar path
        } else {
            const pathSegments = filePath.split('/');
            filePath = pathSegments[pathSegments.length - 1]; // Tomar solo la última carpeta
        }

        console.log("📂 Subiendo archivo a:", filePath); // 📌 Debugging

        await importarFile(getId, selectedFile, filePath); // 📌 Subir archivo a la carpeta actual
        listarContenidos(currentFolder); // 🔄 Refrescar lista de archivos
    };

    const editarCarpeta = async (oldName, folderPath) => {
        const newName = prompt("Ingrese el nuevo nombre de la carpeta:", oldName);

        if (!newName || newName.trim() === "") {
            alert("⚠️ El nombre de la carpeta no puede estar vacío.");
            return;
        }

        try {
            // ✅ Extraer solo el nombre de la carpeta actual para `path`
            let path = folderPath;
            if (!path || path === getId) {
                path = ''; // Si está en la raíz, no enviar `path`
            } else {
                const pathSegments = path.split('/');
                path = pathSegments[pathSegments.length - 1]; // Tomar solo la última carpeta
            }

            console.log(`📝 Renombrando carpeta "${oldName}" a "${newName}" en:`, path); // 📌 Debug

            const response = await putFolder(getId, newName, oldName, path);

            if (response.status === 200) {
                getAlert({ msj: "Carpeta renombrada con éxito.", tipe: 'success' });
                listarContenidos(currentFolder); // 🔄 Refrescar la vista
            } else {
                getAlert({ msj: response.data.message, tipe: 'error' });
            }
        } catch (error) {
            console.error("❌ Error al renombrar la carpeta:", error);
            getAlert({ msj: "Error al renombrar la carpeta.", tipe: 'error' });
        }
    };

    const abrirArchivo = (fileName) => {
        // ✅ Construir la URL completa del archivo
        const fullFilePath = currentFolder ? `${currentFolder}/${fileName}` : fileName;
        const fileURL = `${process.env.REACT_APP_API_URL}/download?file=${fullFilePath}`;

        // ✅ Intentar abrir el archivo en una nueva pestaña
        window.open(fileURL, '_blank');
    };

    const handleMoveItem = async (itemName, currentFolder, newFolder) => {
        try {
            const sourcePath = currentFolder ? `${currentFolder}/${itemName}` : itemName;
            const targetPath = newFolder ? `${newFolder}/${itemName}` : itemName;

            console.log(`🔄 Moviendo "${itemName}" de "${sourcePath}" a "${targetPath}"`);

            const response = await moveFolder(getId, sourcePath, targetPath);

            if (response && response.success) {
                getAlert({ msj: "📂 Elemento movido con éxito", tipe: 'success' });
                listarContenidos(currentFolder); // 🔄 Actualizar vista
            } else {
                getAlert({ msj: "⚠️ No se pudo mover el elemento", tipe: 'error' });
            }
        } catch (error) {
            console.error("❌ Error al mover el archivo/carpeta:", error);
            getAlert({ msj: "Error al mover el archivo/carpeta.", tipe: 'error' });
        }
    };

    const handleMultipleFileUpload = async (event) => {
        const files = event.target.files; // 📂 Archivos seleccionados

        if (!files || files.length === 0) {
            console.error("⚠️ No has seleccionado archivos.");
            return;
        }

        try {
            let filePath = currentFolder;
            if (!filePath || filePath === getId) {
                filePath = ''; // Si estamos en la raíz, no enviar path
            } else {
                const pathSegments = filePath.split('/');
                filePath = pathSegments[pathSegments.length - 1]; // Tomar solo la última carpeta
            }

            console.log(`📂 Subiendo ${files.length} archivos a:`, filePath);

            await uploadMultipleFiles(getId, files, filePath); // 📌 Llamar a la función de importación múltiple

            listarContenidos(currentFolder); // 🔄 Refrescar lista
            getAlert({ msj: "📂 Archivos subidos con éxito.", tipe: 'success' });

        } catch (error) {
            console.error("❌ Error al subir archivos múltiples:", error);
            getAlert({ msj: "Error al subir archivos.", tipe: 'error' });
        }
    };

    const handleDropOnFolder = async (folderName) => {
        if (!draggingFile) return;

        try {
            console.log(`📂 Moviendo "${draggingFile}" a la carpeta "${folderName}"`);

            const sourcePath = currentFolder ? `${currentFolder}/${draggingFile}` : draggingFile;
            const targetPath = `${currentFolder}/${folderName}/${draggingFile}`;

            console.log(`🔄 Verificando existencia de archivo en: ${sourcePath}`);

            // 🔍 Obtener lista de archivos en la carpeta actual
            const contents = await getFolderContents(currentFolder, '');
            console.log(`📋 Contenidos en ${currentFolder}:`, contents);

            if (!contents || !contents.files) {
                console.error("⚠️ No se pudo obtener la lista de archivos.");
                getAlert({ msj: "Error al obtener la lista de archivos.", tipe: 'error' });
                return;
            }

            const archivos = contents.files.map(file => file.name);
            console.log(`📂 Archivos disponibles:`, archivos);

            if (!archivos.includes(draggingFile)) {
                console.error(`⚠️ El archivo "${draggingFile}" no existe en la carpeta original.`);
                getAlert({ msj: `El archivo "${draggingFile}" no existe en la carpeta original.`, tipe: 'error' });
                return;
            }

            console.log(`✅ Archivo encontrado. Moviendo de "${sourcePath}" a "${targetPath}"`);

            const response = await moveFolder(getId, sourcePath, targetPath);

            if (response && response.success) {
                getAlert({ msj: `📂 Archivo movido a "${folderName}" correctamente`, tipe: 'success' });
                listarContenidos(currentFolder); // 🔄 Refrescar lista después del movimiento
            } else {
                console.error("⚠️ No se pudo mover el archivo:", response);
                getAlert({ msj: response.message || "No se pudo mover el archivo.", tipe: 'error' });
            }
        } catch (error) {
            console.error("❌ Error al mover el archivo:", error);
            getAlert({ msj: "Error al mover el archivo.", tipe: 'error' });
        }

        setDraggingFile(null);
        setDraggingOverFolder(null);
    };


    return (

        <div
            className={`contenedor-documentos ${dragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
        >            <div>
                <p className="titulo">Mis documentos</p>

                <div className="breadcrumbs">
                    {rutaActual.length > 0 && (
                        <>
                            <span onClick={() => volverAtras()} className="breadcrumb">◀ Atrás</span>
                            {rutaActual.map((folder, index) => (
                                <span key={index} className="breadcrumb">
                                    {folder} {index < rutaActual.length - 1 ? " / " : ""}
                                </span>
                            ))}
                        </>
                    )}
                </div>

                <OptionComponentDocuments
                    type="text"
                    placeHolder="Buscar por nombre, archivo"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    modalView={modalView}
                    setModalView={setModalView}
                    modalViewExport={modalViewExport}
                    setModalViewExport={setModalViewExport}
                    setModalCreateFolder={setModalCreateFolder}
                    handleFileChange={handleFileUpload}
                />
                {modalCreateFolder && (
                            <div className="modalCreateFolder">
                                <button className="closeButton" onClick={() => setModalCreateFolder(false)}>
                            &times;
                        </button>
                        <div className="contenedorCrearCarpeta">
                            <p>Crear nueva carpeta</p>
                            <input
                                type="text"
                                value={newFolderName}
                                onChange={(e) => setNewFolderName(e.target.value)}
                                placeholder="Nombre de la carpeta"
                                className="inputCreateFolder"
                            />
                            <div className="button-container">
                                <button
                                    className="button button-confirm"
                                    onClick={handleCreateFolder}
                                >
                                    Confirmar
                                </button>
                                <button
                                    className="button button-cancel"
                                    onClick={() => setModalCreateFolder(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {modalViewExport && (
                    <div className="modalExport">
                        <p>Selecciona los archivos y carpetas que deseas descargar:</p>

                        {/* 📤 Botón para importar archivos */}
                        <label className="button">
                            📤 Importar Archivo
                            <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
                        </label>

                        {/* 📥 Botón para exportar archivos seleccionados */}
                        <Button onClick={descargarSeleccionados} msj='📥 Exportar seleccionados' tipe='violeta' />

                        {/* ❌ Botón para cerrar el modal */}
                        <Button onClick={() => setModalViewExport(false)} msj='Cerrar' tipe='gris' />
                    </div>
                )}


                {dragging && (
                    <div className="drag-overlay">
                        <p>📂 Suelta los archivos aquí para subirlos</p>
                    </div>
                )}
                <div className="carpetas-container-general">
                    <h3>Carpetas</h3>
                </div>

                <div className="carpetas-container">
                    {carpetas.length > 0 ? (
                        carpetas.map((carpeta, index) => (
                            <div
                                key={index}
                                className={`carpeta-card ${draggingOverFolder === carpeta.name ? 'resaltado' : ''}`}
                                onDragOver={(event) => handleDragOver(event, carpeta.name)}
                                onDrop={() => handleDropOnFolder(carpeta.name)}
                            >
                                {/* 📂 Ícono de carpeta (para abrirla) */}
                                <div className="carpeta-icono" onClick={() => abrirCarpeta(carpeta.name)}>
                                    <FontAwesomeIcon icon={faFolder} size="3x" />
                                </div>

                                {/* 📌 Nombre de la carpeta (también permite abrir) */}
                                <p className="nombre-carpeta" onClick={() => abrirCarpeta(carpeta.name)}>
                                    {carpeta.name}
                                </p>

                                {/* 🔽 Botón de opciones */}
                                <FontAwesomeIcon icon={faEllipsisV} className="opciones-icono" onClick={() => toggleOptions(index)} />

                                {/* 📌 Opciones de la carpeta */}
                                {showOptions === index && (
                                    <div className="carpeta-opciones">
                                        <p onClick={() => editarCarpeta(carpeta.name, currentFolder)}>Cambiar nombre</p>
                                        <p onClick={() => eliminarCarpeta(carpeta.name)}>Eliminar</p>
                                        <p onClick={() => descargarArchivo(carpeta.name)}>Descargar</p>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron carpetas</p>
                    )}
                </div>


                <div className="archivos-container">
                    <h3>Archivos</h3>
                    {archivos.length > 0 ? (
                        archivos.map((archivo, index2) => (
                            <div
                                key={index2}
                                className="archivo-card"
                                draggable
                                onDragStart={() => handleDragStart(archivo.name)}
                                onClick={() => descargarArchivo(archivo.name)} // Un clic = Descargar
                                onDoubleClick={() => abrirArchivo(archivo.name)} // Doble clic = Abrir
                            >
                                <div className="archivo-icono">
                                    <FontAwesomeIcon icon={faFile} size="3x" />
                                </div>
                                <p className="nombre-archivo">{archivo.name}</p>

                                {/* 🔽 Botón de opciones */}
                                <FontAwesomeIcon icon={faEllipsisV} className="opciones-icono" onClick={() => toggleOptions2(index2)} />

                                {/* 📌 Opciones de la carpeta */}
                                {/* 📌 Opciones de archivos */}
                                {showOptions === index2 && (
                                    <div className="carpeta-opciones">
                                        <p onClick={() => editarCarpeta(archivo.name, currentFolder)}>Camiar nombre</p>
                                        <p onClick={() => eliminarCarpeta(archivo.name)}>Eliminar</p>
                                        <p onClick={() => descargarArchivo(archivo.name)}>Descargar</p>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron archivos</p>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Documentos;