import React, { useEffect, useRef, useState } from "react";

const BarcodeScanner = ({ onScan }) => {
    const focusDivRef = useRef(null);
    const [scannerActive, setScannerActive] = useState(true);
    const [isSelectOpen, setIsSelectOpen] = useState(false); // Estado para saber si el select está abierto

    useEffect(() => {
        let scanBuffer = "";

        const handleKeyDown = (event) => {
            if (!scannerActive || isSelectOpen) return; // No escanear si el escáner está desactivado o el select está abierto

            if (event.key === "Enter") {
                if (scanBuffer.trim().length > 0) {
                    console.log("Datos escaneados:", scanBuffer);
                    onScan(scanBuffer);
                    scanBuffer = ""; // Limpiar buffer después de enviar

                    // 🔹 DESACTIVAR EL ESCÁNER AUTOMÁTICAMENTE TRAS EL ESCANEO
                    setScannerActive(false);
                }
            } else if (event.key.length === 1) {
                scanBuffer += event.key; // Almacenar solo caracteres imprimibles
            }
        };

        // 🔹 DETECTAR CUANDO `react-select` SE ABRE Y DESACTIVAR EL ESCÁNER
        const handleFocusIn = (event) => {
            const isReactSelect = event.target.closest(".react-select__control");
            if (isReactSelect) {
                console.log("Selector abierto, desactivando escáner.");
                setIsSelectOpen(true);
                setScannerActive(false); // Desactivar el escáner mientras el select está abierto
            } else if (["INPUT", "TEXTAREA"].includes(event.target.tagName)) {
                setScannerActive(false); // Desactivar escáner cuando se usa un input o textarea
            }
        };

        // 🔹 DETECTAR CUANDO EL SELECT SE CIERRA Y REACTIVAR EL ESCÁNER
        const handleClickOutsideInputs = (event) => {
            const isReactSelect = event.target.closest(".react-select__control");
            if (!isReactSelect && !["INPUT", "TEXTAREA"].includes(event.target.tagName)) {
                console.log("Clic fuera de input/select, activando escáner.");
                setIsSelectOpen(false);
                setTimeout(() => {
                    setScannerActive(true);
                    if (focusDivRef.current) {
                        focusDivRef.current.focus();
                    }
                }, 300);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        document.addEventListener("focusin", handleFocusIn);
        document.addEventListener("click", handleClickOutsideInputs);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("focusin", handleFocusIn);
            document.removeEventListener("click", handleClickOutsideInputs);
        };
    }, [onScan, scannerActive, isSelectOpen]);

    return (
        <div
            ref={focusDivRef}
            tabIndex={-1}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "1px",
                height: "1px",
                opacity: 0,
                pointerEvents: "none", // 🔹 EVITA INTERFERENCIA CON EL SELECT
            }}
        />
    );
};

export default BarcodeScanner;
