/* eslint-disable no-unused-expressions */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ContextProvider } from './utils/ContextProvider';
import Home from './pages/home';
import LoginPage from './pages/Login';
import Asistencia from './pages/Asistencia';
import ResetPassword from './components/ResetPassword';
import Calendario from './components/Calendario';
import Empleados from './pages/Empleados';
import Objetivos from './pages/Objetivos';
import Empleado from './components/Empleado';
import Importador from './components/Importador';
import Jornada from './pages/Jornada';
import CalendarioEmployee from './pages/CalenderEmployee';
import DynamicRoutePage from './pages/DynamicRoutePage';
import Layout from './components/Layout';
import './index.css'
import Solicitudes from './pages/Solicitudes';
import MiPerfil from './components/MiPerfil';
import InformacionObjetivoModal from './components/InformacionObjetivoModal';
import Registro from './pages/Registro';
import RegistroEmpleado from './components/RegistroEmpleado';
import Visitantes from './pages/Visitantes';
import Reloj from './pages/Reloj';
import Documentos from './pages/Documentos';
import Ausencias from './pages/Ausencia';
import Suspensiones from './pages/Suspensiones';
import Tardanzas from './pages/Tardanzas';
import RelojEventos from './pages/RelojEventos';
import RelojReportes from './pages/RelojReportes';
import RelojResumen from './pages/RelojResumen';
import SolicitudHistorial from './forms/Solicitud/SolicitudHistorial';
import Vacaciones from './forms/Vacaciones/Vacaciones';

function App() {
  const theme = createTheme();

  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/home' element={<Home />} />
              <Route path='/registro' element={<Registro />} />
              <Route path='/registro/:id/:name' element={<RegistroEmpleado />} />
              <Route path='/visitantes' element={<Visitantes />} />
              <Route path='/asistencias' element={<Asistencia />} />
              <Route path='/resetpassword' element={<ResetPassword />} />
              <Route path='/calendario' element={<Calendario />} />
              <Route path='/empleados' element={<Empleados />} />
              <Route path='/miperfil' element={<MiPerfil />} />
              <Route path='/objetivos' element={<Objetivos />} />
              <Route path='/objetivo/:id' element={<InformacionObjetivoModal />} />
              <Route path='/solicitudes/:id?' element={<Solicitudes />} />
              <Route path='/vacaciones/:id?' element={<Vacaciones />} />
              <Route path='/solicitudHistorial/:id/:uuid' element={<SolicitudHistorial />} />
              <Route path='/empleado/:id' element={<Empleado />} />
              <Route path='/importador' element={<Importador />} />
              <Route path='/jornada/:id' element={<Jornada />} />
              <Route path='/calendarioUsuario' element={<CalendarioEmployee />} />
              <Route path='/reloj' element={<Reloj />} />
              <Route path='/documentos' element={<Documentos />} />
              <Route path='/ausencias/:id' element={<Ausencias />} />
              <Route path='/suspensiones/:id' element={<Suspensiones />} />
              <Route path='/tardanzas/:id' element={<Tardanzas />} />
              <Route path='/relojEventos' element={<RelojEventos />} />
              <Route path='/relojReportes' element={<RelojReportes />} />
              <Route path='/relojResumentEmpleados' element={<RelojResumen />} />
              <Route path='/dynamic-route/blog/:blogId/post/:postId/' element={<DynamicRoutePage />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </ContextProvider>
  );
}

export default App;